/**
 * エディター用CSS
 */

$breakPoint: 768px;

.cke_editable {
  padding: 15px;
}

/* Editor Parts
----------------------------------------------- */

.bs-main-contents,
.bge-contents,
.cke_editable {
  font-family: Arial, Verdana, "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  color: #000;

  h1 {
    font-size: 40px;
    margin-bottom: 40px;
    font-weight: bold;
  }

  h2 {
    font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    letter-spacing: 0;
    overflow: hidden;
    font-size: 2.2em;
    font-weight: bold;
    line-height: 1.2;
    margin: 0.6em 0;
    padding: 0.3em 0;
    text-align: left;
    background: none;
    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin: 56px 0 20px 0;
    padding: 13px 28px 12px;
    font-size: 21px;
    font-weight: 700;
    background: #f0f0f0;
    border-radius: 10px;
    line-height: 48.3px;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }
  }

  h4 {
    border-bottom: 1px #CCC dotted;
    font-size: 22px;
    padding-bottom: 12px;
    font-weight: bold;
    padding-left: 0;
    margin: 50px 0 17px 0;
    overflow: hidden;
    position: relative;

    &:first-child {
      margin-top: 0;
    }
  }

  h5 {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.2;
    margin: 1em 0;
    padding: 0.3em 0;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }
  }

  h6 {
    font-size: 1em;
    font-weight: bold;
    line-height: 1.2;
    margin: 1em 0;
    border: none;
    padding: 0.3em 0;
    overflow: hidden;

    &:first-child {
      margin-top: 0;
    }
  }

  strong {
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
  }

  u {
    font-weight: normal;
    font-style: normal;
    text-decoration: underline;
  }

  s {
    font-weight: normal;
    font-style: normal;
    text-decoration: line-through;
  }

  em {
    font-style: italic;
  }

  hr {
    width: 100%;
    height: 1px;
    color: #ddd;
    background: currentColor;
    border-style: none;
    margin: 50px auto;

    &:first-child {
      margin-top: 0;
    }
  }

  ul {
    margin: 20px 0 36px 0;
    line-height: 1.6;
    letter-spacing: .05rem;

    &:first-child {
      margin-top: 0;
    }

    li {
      list-style-position: inside;
      position: relative;
      padding: 0 0 0 25px;
      margin: 0.5em 0 0.5em 0;
      list-style-type: none;
      overflow: hidden;

      a {
        zoom: 1;
      }

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 9px;
        left: 1px;
        width: 6px;
        height: 6px;
        background: #181818;
        border-radius: 50%;
      }
    }

    ul {
      margin: 0 0 0 1.8em;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  ol {
    margin: 20px 0 36px 1.8em;
    line-height: 1.6;
    letter-spacing: .05rem;

    ol {
      margin: 20px;
    }

    li {
      list-style-type: decimal-leading-zero;
      position: relative;
      padding: 0 0 0 0;
      margin: 0.5em 0 0.5em 0;
      line-height: 1.6;
    }
  }

  p {
    margin: 1em 0;
    letter-spacing: .05rem;
    margin-bottom: 20px;
    line-height: 2.3;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    margin: 20px 0 20px 0;

    @media screen and (max-width: $breakPoint) {
      width: 100%;
      display: block;
    }

    &:first-child {
      margin-top: 0;
    }

    caption {
      font-weight: bold;
      text-align: left;

      @media screen and (max-width: $breakPoint) {
        display: block;
      }
    }

    tbody,
    tfoot {
      @media screen and (max-width: $breakPoint) {
        display: block;
      }
    }

    tr {
      border-top: 1px solid #e1e1e1;
      @media screen and (max-width: $breakPoint) {
        display: block;
        margin-top: -1px;
      }

      &:nth-child(odd) td {
        background: #fff;
      }

      th,
      td {
        text-align: left;
        padding: 26px 24px;
        border-left: 1px solid #e1e1e1;

        @media screen and (max-width: $breakPoint) {
          width: auto;
          display: block;
        }
      }

      th {
        font-weight: bold;
        font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
        background: #f3f3f3;
        @media screen and (max-width: $breakPoint) {
          border-bottom: none;
        }
      }

      td {
        font-weight: normal;

        ul {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  fieldset {
    border: 0;
  }

  img {
    border: 0;
    margin: auto;
  }

  address, button, caption, cite, code, dfn, em, input, optgroup, option, select, strong, textarea, th, var {
    font: inherit;
  }

  small {
    color: #666;
  }

  del, ins {
    text-decoration: none;
  }

  a {
    &:link, &:visited {
      color: #2b7bb9;
    }

    &:hover {
      text-decoration: none;
    }
  }

  caption, th {
    text-align: left;
  }

  q {
    &:before, &:after {
      content: '';
    }
  }

  abbr, acronym {
    border: 0;
    font-variant: normal;
  }

  sub {
    font-size: 0.8em;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    vertical-align: sub;
  }

  sup {
    font-size: 0.8em;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    vertical-align: super;
  }

  legend {
    color: #000;
  }

  pre {
    background-color: #F3F3F3;
    text-align: left;
    padding: 10px 20px;
    margin-bottom: 20px;
    overflow: auto;
    border: 1px solid #E2E2E2;
  }

  blockquote {
    position: relative;
    margin: 1em 0;
    padding: 1px 2.5em;
    border-radius: 5px;
    background: none;
    border: 2px solid #ccc;
    overflow: hidden;
    color: #888;
    font-size: 0.9em;

    &:first-child {
      margin-top: 0;
    }

    &::before {
      content: '“';
      display: block;
      font-family: serif;
      position: absolute;
      top: 0.3em;
      left: 0.25em;
      z-index: 0;
      color: #ccc;
      font-size: 3em;
    }

    &::after {
      content: '”';
      display: block;
      font-family: serif;
      position: absolute;
      bottom: -0.3em;
      right: 0.25em;
      z-index: 0;
      color: #ccc;
      font-size: 3em;
    }
  }

}

/* Form Parts
----------------------------------------------- */

.bs-main-contents,
.bge-contents,
.cke_editable,
.bs-sub-contents {
  form {
    display: inline;
  }

  input, textarea, select, option {
    font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  }

  input[type=text],
  input[type=email],
  input[type=tel],
  input[type=number],
  textarea,
  select {
    margin: 3px 2px;
    padding: 8px 10px;
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  input {
    &[type=password] {
      margin: 3px 2px;
      padding: 3px 1px;
      border: 1px solid #ccc;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }

    &[type=checkbox] {
      vertical-align: middle;
    }

    &[type=radio], &[type=checkbox] {
      margin-left: 5px;
      margin-right: 5px;
    }

    &[type=submit], &[type=reset], &[type=button] {
      margin: 3px 2px;
      font-family: "SF Pro JP", "SF Pro Text", "SF Pro Icons", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
    }

    &[type=text],
    &[type=email],
    &[type=tel],
    &[type=number] {
      font-size: 1em;

      &::placeholder {
        color: #ccc;
      }
    }

    &:active,
    &:focus {
      box-shadow: 0 0 5px 1px #fa9696;
      outline: none;
    }
  }

  textarea, input[type=password] {
    font-size: 1em;
    padding: 3px 3px;
  }

  textarea {
    width: 95%;

    &:active,
    &:focus {
      box-shadow: 0 0 5px 1px #fa9696;
      outline: none;
    }
  }

  input {
    &[type=text],
    &[type=email],
    &[type=tel],
    &[type=number],
    &[type=password] {
      line-height: 1em;
    }
  }

  select {
    font-size: 1em;
    height: 2.6em;
    padding: 10px 8px;

    &:active,
    &:focus {
      box-shadow: 0 0 5px 1px #fa9696;
      outline: none;
    }
  }

  optgroup option {
    padding-left: 20px;
  }
}
